import { ColorGenerator } from "@tucktrucks/core";

export const barChartColours = (number) =>
  ColorGenerator.getRange(number, 0.6, 0.98, 1, 0.6).map((color) =>
    color.toString()
  );

export const barChartColours2 = (number) =>
  ColorGenerator.getRange(number, 0.98, 0.98, 0.1, 0.25).map((color) =>
    color.toString()
  );

export const pieChartColours = (number) => {
  let numberToGet = Number(number) === 1 ? 2 : number;
  let hTo = 0.4;
  if (numberToGet <= 4) {
    hTo = 0.8;
  }
  return ColorGenerator.getRange(numberToGet, 0.6, 0.98, 1, hTo).map((color) =>
    color.toString()
  );
};
