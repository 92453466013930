<template>
  <div class="no-data-wrap">
    <slot></slot>
  </div>
</template>

<style lang="scss">
  .no-data-wrap {
    font-size: 1rem;
    text-align: center;
    color: #ddd;
    font-style: italic;
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
